// Register smaps namespace
API.SMaps = {};
API.SMaps.Init = function(containerId, lat, lng, zoom, enableControls = true){
  var center = SMap.Coords.fromWGS84(lng, lat);
  var map = new SMap(JAK.gel(containerId), center, zoom);
  map.addDefaultLayer(SMap.DEF_BASE).enable();

  if(enableControls)
    map.addDefaultControls();

  var marker = new SMap.Layer.Marker();
  map.addLayer(marker);
  marker.enable();

  return {"map": map,"markerLayer": marker};
};

API.SMaps.AddMarker = function(map, lat, lng, options = {}, card = null){
  var layer = map.markerLayer;
  var point = SMap.Coords.fromWGS84(lng, lat);

  var marker = new SMap.Marker(point, false, options);
  if(card){
    var scard = new SMap.Card();
    scard.getHeader().innerHTML = card.header ? card.header : "";
    scard.getBody().innerHTML = card.body ? card.body : "";
    scard.getFooter().innerHTML = card.footer ? card.footer : "";
    marker.decorate(SMap.Marker.Feature.Card, scard);
  }

  layer.addMarker(marker);
};
